define([
  'jquery'],
function ($) {
  var scrollToSection = function (cssSelector, callback) {
    let $container, scrollTop;
    const globals = window.globals;
    let offset = globals.scrollOffset || 0;
    if ($('#gcc-site-container').length > 0) {
      let headerHeight = 0;
      $container = $('#gcc-site-container');
      if (globals.breakpoint.value === 'small' || globals.breakpoint.value === 'medium') {
        headerHeight = ($('#gcc-header').outerHeight() + $('#gcc-header-search').outerHeight() + $('#gcc-header-banner').outerHeight());
      } else {
        headerHeight = ($('#gcc-header').outerHeight() + $('#gcc-header-banner').outerHeight());
      }
      scrollTop = $(cssSelector).position().top + offset + headerHeight;
    } else {
      $container = $('html, body');
      scrollTop = $(cssSelector).offset().top + offset;
    }
    $container.animate({
      scrollTop: scrollTop
    }, 1000, function() {
      if (callback) {
        callback();
      }
    });
  }
  return scrollToSection;
});
