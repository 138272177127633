// Moving this up and out of the function sinc this is needed for down stream code
// The function is not always run and that would break other integrations
const isPlaAlreadyInStorage = window.sessionStorage.getItem('pla');

if (!isPlaAlreadyInStorage) {

  function getUrlParameter(parameter) {
    const sanitizedParameter = parameter
      .replace(/[\[]/, '\\[') // eslint-disable-line
      .replace(/[\]]/, '\\]'); // eslint-disable-line
    const regex = new RegExp(`[\\?&]${sanitizedParameter}=([^&#]*)`);
    const results = regex.exec(window.location.search);

    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
  
  const isPlaUrl = window.location.href.indexOf('/pla/') > -1;
  // NOTE: utm_medium comes from google as a custom parameter
  const isPlaParamiter = getUrlParameter('utm_medium') === 'pla';
  if (isPlaUrl || isPlaParamiter) window.sessionStorage.setItem('pla', 'true');
}

define([
  "jquery",
  "globals",
  "lodash",
  "modernizr"],
  function ($, globals, _) {


    "use strict";

    const device = require('current-device').default;

    document.addEventListener('loginStatusChange',
      (e) => {
        if (e.detail.isLoggedIn) {
          sessionStorage.setItem('isLoggedIn', true);
          $("#gcc-header-topnav-myaccount").css('display', 'block');
          $("#gcc-header-topnav-myaccount-sign-in").css('display', 'none');
        } else {
          sessionStorage.removeItem('isLoggedIn');
          $("#gcc-header-topnav-myaccount-sign-in").css('display', 'block');
          $("#gcc-header-topnav-myaccount").css('display', 'none');
        }
      });

    (function () {
      if (typeof window.CustomEvent === "function") return false; //If not IE

      function CustomEvent(event, params) {
        params = params || { bubbles: false, cancelable: false, detail: undefined };
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
      }

      CustomEvent.prototype = window.Event.prototype;

      window.CustomEvent = CustomEvent;
    })();

    if (globals.siteId === 1) {
      require(["header-ab"]);
    }
    else if (globals.siteId === 2) {
      require(["header-bca"]);
    }
    else if (globals.siteId === 3) {
      require(["header-bdc"]);
    }
    else if (globals.siteId === 5) {
      require(["header-jb"]);
    }
    else if (globals.siteId === 11) {
      require(["header-thd"]);
    }
  });
