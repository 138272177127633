define( [
  "jquery",
  "cookies-factory"],
  function ( $,cookiesFactory ) {
        
      if ( cookiesFactory.isInternalUser() ) {
          // hide the right rails layout menu 
        $( ".bdc-rrf" ).hide();
        $( ".slider-icons" ).hide();
      }

  } );