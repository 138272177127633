(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
      // AMD
      define(['underscore'], factory);
  } else if (typeof exports === 'object') {
      // Node, CommonJS-like
      module.exports = factory(require('underscore'));
  } else {
      // Browser globals (root is window)
      root.returnExports = factory(root._);
  }
}(this, function (_) {
  //    methods
  var readCookie = function(name, c, C, i) {
    c = document.cookie.split("; ");
    var cookies = {};
    for (i = c.length - 1; i >= 0; i--) {
      C = c[i].split("=");
      cookies[C[0]] = C[1];
    }
    return cookies[name];
  };

  var getCartOnBehalfOf = function() {
    var blindsCookie = readCookie("CartOnBehalfOf");
    return blindsCookie;
  }

  var getDateAsOf = function() {
    var blindsCookie = readCookie("dateAsOf");
    if (blindsCookie === undefined) {
      return undefined;
    }
    //TODO: remove this entire feature!!!
    //var fullDateTime = blindsCookie.substr(0, blindsCookie.indexOf("|"));
    // HACK - Spit away the time from the view
      return undefined;
  };

  var getUsername = function() {
    var blindsCookie = readCookie("internalUser");
    if (blindsCookie === undefined) {
      return undefined;
    }
    return blindsCookie.substr(0, blindsCookie.indexOf(":"));
  };

  return {

    addAjaxCacheHeader: function(xhr, settings) {
      return addAjaxCacheHeader(xhr, settings);
    },

    isInternalUser: function() {
      return getUsername() !== undefined;
    },
    checkInternal: function(callback) {
      var name = getUsername();
      if (name === undefined) {
        callback(false);
        return;
      }
      callback(true);
    },

    dateAsOf: function() {
      return getDateAsOf();
    },

    cartOnBehalfOf: function() {
      return getCartOnBehalfOf();
    },

    userName: function() {
      return getUsername();
    },

    userNameWithoutDomainPrefix: function () {
      var username = getUsername();
      if ( username && username.indexOf( '\\' ) > -1 ) {
        username = _.last( username.split( '\\' ) );
      }
      return username;
    }
  };
}));
