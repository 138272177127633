import React from 'react';
import PropTypes from 'prop-types';

const EstimateBanner = ({ location, data }) => {
  if (!location || !data) return null;
  if (location.pathname.toLowerCase().indexOf('estimatebuilder') !== -1) return null;

  const { action, estimateName } = JSON.parse(data);
  const editText = action === 'edit' ? 'editing' : 'adding to';

  return (
    <div className="estimate-banner">
      <div className="estimate-actions">
        <div className="text">
          <span className="mobile">{editText}</span>
          <a href="/estimatebuilder">
            <span className="mobile estimate-name">{estimateName}</span>
            <span className="desktop estimate-name">
              You&#39;re currently {editText} {estimateName}. Click here to return to your estimate.
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

EstimateBanner.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.string,
};

EstimateBanner.defaultProps = {
  data: undefined,
};

export default EstimateBanner;
