define([
  "global-config",
  "page-context-javascript",
  "underscore",
  "knockout",
  "jquery",
  "postal",
  "ssm",
  "es6-promise",
  "js-cookie",
  "scroll-to-section",
  "react-dom",
  "../react/estimateBuilder/components/EstimateBanner",
  "react",
  "right-rails-fly-out-menu",
  "colorbox",
  "sticky-kit",
  "slick",
  "unveil",
  "jquery-validate"
], function(
  globalConfig,
  pageContextJavascript,
  _,
  ko,
  $,
  postal,
  ssm,
  promise,
  Cookies,
  scrollToSection,
  ReactDOM,
  estimateBanner,
  React
) {
  "use strict";
  promise.polyfill();
  const EstimateBanner = estimateBanner.default;

  const estimateBannerRoot = document.getElementById("estimate-banner-root");

  if (estimateBannerRoot) {
    ReactDOM.render(
      <EstimateBanner
        location={window.location}
        data={window.localStorage.getItem("estimateMode")}
      />,
      estimateBannerRoot
    );
  }

  // Configures SimpleStateManager States
  (function(ssm) {
    var ObservableState = function(id, query) {
      var self = this;
      self.id = id;
      self.query = query;
      self.observableIsActive = ko.observable(false);
      self.onEnter = function() {
        self.observableIsActive(true);
      };
      self.onLeave = function() {
        self.observableIsActive(false);
      };
    };

    ssm.addState(new ObservableState("mobile", "(max-width: 767px)"));
    ssm.addState(new ObservableState("tablet", "(min-device-width: 768px)"));
    ssm.addState(new ObservableState("desktop", "(min-device-width: 1024px)"));
    ssm.addState(
      new ObservableState(
        "tablet-only",
        "(min-device-width: 768px) and (max-device-width: 1024px)"
      )
    );
  })(ssm);

  var globals = {
    alert: function() {
      return window ? window.alert : {};
    },
    breakpoint: {},
    ssm: ssm,
    dictionaryEntryForTerm:
      "/DictionaryManagement/Definition/DictionaryEntryForTerm",
    setBodyOverlay: function(bodyOverlay) {
      if (bodyOverlay) {
        $("body").addClass("bdc-remove-body-scrolling");
      } else {
        $("body").removeClass("bdc-remove-body-scrolling");
      }
    }
  };

  _.extend(globals, window.globalConfig);
  _.extend(globals, pageContextJavascript);

  globals.breakpoint.refreshValue = function() {
    this.value = window
      .getComputedStyle(document.querySelector("body"), ":before")
      .getPropertyValue("content")
      .replace(/\"/g, "");
  };
  globals.breakpoint.refreshValue();

  window.addEventListener("resize", function(e) {
    globals.breakpoint.refreshValue();
  });

  window.addEventListener("orientationchange", function() {
    globals.breakpoint.refreshValue();
  });

  // Browser specific JavaScript
  var userAgent = window.navigator.userAgent;
  var isIE =
    userAgent &&
    (userAgent.indexOf("MSIE ") > 0 || userAgent.indexOf("Trident/") > 0);
  var isIE10 = document.all && window.atob;
  var isIE9 = document.all && document.addEventListener && !window.atob;
  if (isIE) {
    $("html").addClass("ie");
    if (isIE9) {
      require(["ie9-only"]);
    } else if (isIE10) {
      $("html").addClass("ie10");
      require(["ie10-only"]);
    }
  }
  globals.isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  globals.isIE9 = isIE9;

  //exposed globally for infopages
  window.globals = globals;
  window.Cookies = Cookies;
  window.scrollToSection = scrollToSection;
  //503 means we are under construction
  var readCookie = function(name, c, C, i) {
    c = document.cookie.split("; ");
    var cookies = {};
    for (i = c.length - 1; i >= 0; i--) {
      C = c[i].split("=");
      cookies[C[0]] = C[1];
    }
    return cookies[name];
  };
  $.ajaxSetup({
    beforeSend: function(request, settings) {
      var blindsCookie = readCookie("internalUser");
      //Look to see if the request is mentioned in the _cacheCheck list on the page
      if (globals.cacheCheck !== undefined) {
        var hasCheck = _.filter(globals.cacheCheck, function(url) {
          return settings.url.toLowerCase().indexOf(url.toLowerCase()) !== -1;
        });
        //If so, we send a request header for akamai to say it should not cache this response
        if (hasCheck.length > 0) {
          if (blindsCookie !== undefined) {
            request.setRequestHeader("x-blinds-internal", "true");
          }
        }
      }
    },
    error: function(xhr, textStatus, errorThrown) {
      if (xhr.status === 503) {
        var newDoc = document.open("text/html", "replace");
        newDoc.write(xhr.responseText);
        newDoc.close();
      }
    }
  });

  $(function() {
    if (globals.siteId === 3) {
      $(document).on('click', 'a[href*="/surefit"]', function(event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        let output = '';
        const {
          globalConfig: { prismicApi },
        } = window;
        $.ajax({ url: prismicApi }).then(function(val) {
          var ref = val.refs[0].ref;
          $.ajax({
            url: `${prismicApi}/documents/search?ref=${ref}&q=[[at(my.contentpage.uid, "surefit-guarantee-to-fit")]]`
          }).then(function(surefitObj) {
            surefitObj.results[0].data.body[0].items[0].article.forEach(function (obj, index) {
              if (obj.type === 'paragraph') {
                output += `<p class="f6 f7-ns">${obj.text}</p>`;
              } else if (obj.type === 'list-item') {
                if (surefitObj.results[0].data.body[0].items[0].article[index - 1].type !== 'list-item') {
                  output += '<ul class="f6 f7-ns">';
                }
                output += `<li class="mb2">${obj.text}</li>`;
                if (surefitObj.results[0].data.body[0].items[0].article[index + 1] &&
                  surefitObj.results[0].data.body[0].items[0].article[index + 1].type !== 'list-item'
                ) {
                  output += '</ul>';
                }
              }
            });
            var surefitContent = ' \
            <div class="cf ma3 ma4-ns mw6"> \
              <div class="fl w-100 w-20-ns pt3 pv0-ns"> \
                <div class="bdc-vector bdc-surefit f1 tc"></div> \
                <h2 class="f4 f6-ns mt2 tc lh-title">' + surefitObj.results[0].data.page_h1[0].text + '</h2> \
              </div> \
              <div class="fl w-100 w-80-ns pl4-ns"> \
                <h3 class="mb3 tc tl-ns lh-title f5">' + surefitObj.results[0].data.hero_h2_subtitle[0].text + '</h3> \
                ' + output + ' \
              </div> \
            </div>';
            $.colorbox({
              html: surefitContent,
              transition: "none",
              width: '600px',
              maxWidth: '95%',
              maxHeight: '95%',
            });
          });
        });
      });
    }
  });

  // Prevents scrolling of page when any modal (colorbox) is open
  $(document)
    .on("cbox_open", function(event, myName) {
      $("body").css({
        overflow: "hidden"
      });
    })
    .on("cbox_closed", function(event, myName) {
      $("body").css({
        overflow: ""
      });
    });

  (function() {
    if (
      "-ms-user-select" in document.documentElement.style &&
      navigator.userAgent.match(/iemobile/i) === true
    ) {
      var msViewportStyle = document.createElement("style");
      msViewportStyle.appendChild(
        document.createTextNode("@-ms-viewport{width:auto!important}")
      );
      document.getElementsByTagName("head")[0].appendChild(msViewportStyle);
    }
  })();
  return globals;
});
