define(["knockout", "postal"], function (ko, postal) {
  //Changing other elements visibility in the page can mean our sticky isn't placed correctly anymore
  //For example-- a div in the container of the container of our sticky might show, causing the container of the container to grow
  //I've monkey-patched the ko visibility binding so it will publish a message telling our sticky to recalculate its position
  var originalVisible = ko.bindingHandlers.visible.update;
  ko.bindingHandlers.visible.update = function(element, valueAccessor) {
    var ret = originalVisible( element, valueAccessor );
    postal.publish({
      channel: "DOM",
      topic: "visibilityChange"
    });
    return ret;
  };
});

